import specialFormModel from "./specialFormModel";
const {
  formField: {
    name,
    schedule,
    startDate,
    endDate,
    enabled,
    priority,
    promoCodeEnabled,
    promoCode,
    promoCodeUsesPerUser,
    maxUsesPerCart,
    terms,
    tags,
    description,
    photoFile,
    photoUrl,
    menuTypes,
    discounts,
    bundleQualifiers,
    bundleRewards,
    requiredItemsInCart,
    requiredTotalWeight,
    requiredTotalValue,
  },
} = specialFormModel;

const generateInitialValues = (businessId) => {
  const newDate = new Date();
  newDate.setHours(0, 0, 0, 0);
  return {
    [name.name]: "",
    [schedule.name]: {
      activeSunday: true,
      activeMonday: true,
      activeTuesday: true,
      activeWednesday: true,
      activeThursday: true,
      activeFriday: true,
      activeSaturday: true,
    },
    [startDate.name]: newDate,
    [endDate.name]: "",
    [enabled.name]: true,
    [priority.name]: 1,
    [promoCodeEnabled.name]: false,
    [promoCode.name]: "",
    [promoCodeUsesPerUser.name]: 0,
    [maxUsesPerCart.name]: "",
    [terms.name]: "",
    [tags.name]: [],
    [description.name]: "",
    [photoFile.name]: null,
    [menuTypes.name]: [],
    [discounts.name]: [
      {
        collectionId: null,
        isCustomCollection: true,
        customCollection: {
          businessId: businessId,
          name: "",
          collectionType: "SPECIALS",
          filters: [
            {
              includedProductIds: [],
              includedCategories: [],
              includedSubCategories: [],
              includedBrandIds: [],
              excludedProductIds: [],
              excludedCategories: [],
              excludedBrandIds: [],
              weightFilterOperator: "",
              weightFilterValue: "",
            },
          ],
          logicOperator: "AND",
        },
        type: "DOLLAR_AMOUNT",
        value: 0,
        maxValue: null,
      },
    ],
  };
};

const generateInitialValuesEdit = (businessId, special) => {
  return {
    [name.name]: special?.name || "",
    schedule: special?.schedule || {
      activeSunday: true,
      activeMonday: true,
      activeTuesday: true,
      activeWednesday: true,
      activeThursday: true,
      activeFriday: true,
      activeSaturday: true,
    },
    [startDate.name]: special?.startDate || "",
    [endDate.name]: special?.endDate || "",
    [enabled.name]: special?.enabled || true,
    [priority.name]: special?.priority || 1,
    [promoCodeEnabled.name]: special?.promoCodeEnabled || false,
    [promoCode.name]: special?.promoCode || "",
    [promoCodeUsesPerUser.name]: special?.promoCodeUsesPerUser || 0,
    [maxUsesPerCart.name]: special?.maxUsesPerCart || "",
    [terms.name]: special?.terms || "",
    [tags.name]: special?.tags || [],
    [description.name]: special?.description || "",
    [photoUrl.name]: special?.photoUrl || "",
    [photoFile.name]: null,
    [menuTypes.name]: special?.menuTypes || [],
    [discounts.name]: special?.discounts.length > 0 ? special.discounts.map(discount => ({
      ...discount,
      collection: {
        ...discount.collection,
        filters: discount.collection.filters.map(filter => ({
          ...filter,
          includedBrandIds: filter.includedBrandIds.map(key => ({ key, doc_count: 0 })),
          includedProductIds: filter.includedProductIds.map(key => ({ _id: key, _source: { jointId: key } })),
        })),
      },
    })) : [
      {
        collectionId: null,
        isCustomCollection: true,
        collection: {
          businessId: businessId,
          name: special?.name || "Default Collection",
          collectionType: "SPECIALS",
          filters: [
            {
              includedProductIds: [],
              includedCategories: [],
              includedSubCategories: [],
              includedBrandIds: [],
              excludedProductIds: [],
              excludedCategories: [],
              excludedBrandIds: [],
              weightFilterOperator: "",
              weightFilterValue: "",
            },
          ],
          logicOperator: "AND",
        },
        customCollection: {
          businessId: businessId,
          name: special?.name || "Default Collection",
          collectionType: "SPECIALS",
          filters: [
            {
              includedProductIds: [],
              includedCategories: [],
              includedSubCategories: [],
              includedBrandIds: [],
              excludedProductIds: [],
              excludedCategories: [],
              excludedBrandIds: [],
              weightFilterOperator: "",
              weightFilterValue: "",
            },
          ],
          logicOperator: "AND",
        },
        type: "DOLLAR_AMOUNT",
        value: 0,
        maxValue: null,
      },
    ],
  };
};

const generateBundleInitialValues = (businessId) => {
  const newDate = new Date();
  newDate.setHours(0, 0, 0, 0);
  return {
    [name.name]: "",
    [schedule.name]: {
      activeSunday: true,
      activeMonday: true,
      activeTuesday: true,
      activeWednesday: true,
      activeThursday: true,
      activeFriday: true,
      activeSaturday: true,
    },
    [startDate.name]: newDate,
    [endDate.name]: "",
    [enabled.name]: true,
    [priority.name]: 1,
    [promoCodeEnabled.name]: false,
    [promoCode.name]: "",
    [promoCodeUsesPerUser.name]: 0,
    [maxUsesPerCart.name]: "",
    [terms.name]: "",
    [tags.name]: [],
    [description.name]: "",
    [photoFile.name]: null,
    [menuTypes.name]: [],
    [discounts.name]: [],
    [bundleQualifiers.name]: [
      {
        type: "",
        isCustomCollection: true,
        customCollection: {
          businessId: businessId,
          name: "",
          filters: [],
          logicOperator: "AND",
        },
        operator: "GREATER_THAN_OR_EQUAL_TO",
        requiredWeight: null,
        requiredValue: null,
        requiredQuantity: null,
      },
    ],
    [bundleRewards.name]: [],
    [requiredTotalValue.name]: 0,
    [requiredItemsInCart.name]: 0,
    [requiredTotalWeight.name]: 0,
  };
};

const getBundleFilterGroup = (special) => {
  if (special?.bundleQualifiers?.length > 0) {
    const firstQualifier = special.bundleQualifiers[0];
    const { customCollection } = firstQualifier;
    const { filters } = customCollection;
    const firstFilter = filters[0];

    if (firstFilter?.includedProductIds.length > 0 && firstFilter?.includedCategories.length === 0 && firstFilter?.includedBrandIds.length === 0) {
      return "item";
    }
    if (firstFilter?.includedCategories.length > 0 && firstFilter?.includedProductIds.length === 0 && firstFilter?.includedBrandIds.length === 0) {
      return "category";
    }
    if (firstFilter?.includedBrandIds.length > 0 && firstFilter?.includedProductIds.length === 0 && firstFilter?.includedCategories.length === 0) {
      return "brand";
    }
    if (firstFilter?.includedBrandIds.length > 0 && firstFilter?.includedCategories.length > 0 && firstFilter?.includedProductIds.length === 0) {
      return "both";
    }

  }

}

const getBundleRewardType = (special) => {
  if (special?.bundleRewards?.length > 0) {
    const firstReward = special.bundleRewards[0];
    return firstReward.type;
  }
  return null;

}





const generateBundleEditInitialValues = (businessId, special) => {
  return {
    [name.name]: special?.name || "",
    schedule: special?.schedule || {
      activeSunday: true,
      activeMonday: true,
      activeTuesday: true,
      activeWednesday: true,
      activeThursday: true,
      activeFriday: true,
      activeSaturday: true,
    },
    [startDate.name]: special?.startDate || "",
    [endDate.name]: special?.endDate || "",
    [enabled.name]: special?.enabled || true,
    [priority.name]: special?.priorty || 1,
    [promoCodeEnabled.name]: special?.promoCodeEnabled || false,
    [promoCode.name]: special?.promoCode || "",
    [promoCodeUsesPerUser.name]: special?.promoCodeUsesPerUser || 0,
    [maxUsesPerCart.name]: special?.maxUsesPerCart || "",
    [terms.name]: special?.terms || "",
    [tags.name]: special?.tags || [],
    [description.name]: special?.description || "",
    [photoUrl.name]: special?.photoUrl || "",
    [photoFile.name]: null,
    [menuTypes.name]: special?.menuTypes || [],
    [discounts.name]: [],
    [bundleQualifiers.name]: special?.bundleQualifiers?.length > 0 ? special.bundleQualifiers : [
      {
        type: "",
        isCustomCollection: true,
        customCollection: {
          businessId: businessId,
          name: "",
          filters: [],
          logicOperator: "AND",
        },
        operator: "GREATER_THAN_OR_EQUAL_TO",
        requiredWeight: null,
        requiredValue: null,
        requiredQuantity: null,
      },
    ],
    [bundleRewards.name]: special?.bundleRewards?.length > 0 ? special.bundleRewards : [],
    [requiredTotalValue.name]: special?.requiredTotalValue || 0,
    [requiredItemsInCart.name]: special?.requiredItemsInCart || 0,
    [requiredTotalWeight.name]: special?.requiredTotalWeight || 0,
    filterGroup: getBundleFilterGroup(special),
    rewardType: getBundleRewardType(special),
  };
};

export {
  generateBundleInitialValues,
  generateBundleEditInitialValues,
  generateInitialValues,
  generateInitialValuesEdit
};

